import React, {useEffect, useMemo, useRef, useState} from 'react';
import {pdfjs, Document, Page} from 'react-pdf';
import PropTypes from 'prop-types';
import LoadingPreview from './LoadingPreview';
import axios from 'axios';

const FilePresenceChecker = ({file, isEnabled, timeout, maxAttempts, children}) => {
    const [isPresent, setIsPresent] = useState(false)
    const [attempts, setAttempts] = useState(0)
    const attemptsRef = useRef(attempts)
    const isPresentRef = useRef(isPresent)
    const {url} = file

    attemptsRef.current = attempts
    isPresentRef.current = isPresent

    const checkFile = async () => {

        // TODO: This should use a head request, but rails only generates presigned urls for get requests
        const response = await axios.get(url, {
            validateStatus: () => true
        })

        setIsPresent(response.status === 200)
        setAttempts(attemptsRef.current + 1)

        if(isPresentRef.current) {
            setAttempts(0)

            return
        }

        if(attemptsRef.current >= maxAttempts) {
            setIsPresent(false)
            return
        }

        setTimeout(async () => {
            await checkFile()
        }, timeout);
    }

    useEffect(() => {
        (async () => {
                if(!isEnabled) { return }

                await checkFile()
            }
        )()
    }, []);

    if(!isEnabled || isPresent) {
        return children
    }

    return (
        <div className='fa-2x' style={{display: 'flex', justifyContent: 'center'}}>
            <p>
                <i className='fas fa-cog fa-spin' />
                &nbsp;
                Warte auf Dokument...
            </p>
        </div>
    )
}

FilePresenceChecker.propTypes = {
    file: PropTypes.object.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    timeout: PropTypes.number,
    maxAttempts: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const PDFPreview = ({file, print, loadingFile, loadingFailed, showControls, checkPresence}) => {
    const wrapper = useRef()
    const [pages, setPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [scale, setScale] = useState(100)
    const [rotation, setRotation] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const options = useMemo(
        () => ({cMapUrl: 'cmaps/', cMapPacked: true}), []
    )

    const SCALE_UP = 10;
    const SCALE_DOWN = -10;

    const scalePercent = () => scale / 100.0
    const changeScale = (factor) => setScale(Math.max(10, Math.min(scale + factor, 300)))
    const rotate = () => setRotation((rotation + 90) % 360)

    const changePage = (direction) => setCurrentPage(Math.max(1, Math.min(currentPage + direction, pages)))

    const resetView = () => {
        setRotation(0)
        setScale(100)
    }

    const onLoadSuccess = ({ numPages }) => {
        if(numPages !== pages) { setPages(numPages) }

        const bounds = wrapper.current.getBoundingClientRect()

        if(bounds.width !== width || bounds.height !== height) {
            setWidth(bounds.width)
            setHeight(bounds.height)
        }
    }

    return (
        <>
            <div className='preview-wrapper' ref={wrapper}>
                <div className='preview-canvas' height={height} width={width} style={{backgroundColor: '#fff'}}>
                    <FilePresenceChecker file={file} timeout={2000} maxAttempts={30} isEnabled={checkPresence}>
                        <Document options={options} file={file.url} onLoadSuccess={onLoadSuccess} rotate={rotation} loading={<LoadingPreview failed={false} message={loadingFile} />} error={<LoadingPreview failed={true} message={loadingFailed} />}>
                            <Page pageNumber={currentPage} loading='' scale={scalePercent()} width={width} height={height} />
                        </Document>
                    </FilePresenceChecker>
                </div>
            </div>
            { showControls &&
                <div className='controls-wrapper'>
                    <ul className='controls'>
                        { print && <li className='print' onClick={() => print(file)}/> }
                        <li className='download' onClick={() => window.location.href = file.downloadUrl} />
                        <li className='zoom-in' onClick={() => changeScale(SCALE_UP)} />
                        <li className='zoom-out' onClick={() => changeScale(SCALE_DOWN)} />
                        <li className='rotate' onClick={rotate} />
                        <li className='reset' onClick={resetView} />
                    </ul>
                    {pages > 1 &&
                        <ul className='pages'>
                            <li className='prev' onClick={() => changePage(-1)}/>
                            <li>{currentPage}</li>
                            <li className='divider' />
                            <li>{pages}</li>
                            <li className='next' onClick={() => changePage(1)}/>
                        </ul>
                    }
                </div>
            }
        </>
    )

}

PDFPreview.propTypes = {
    file: PropTypes.object.isRequired,
    print: PropTypes.func,
    loadingFile: PropTypes.string.isRequired,
    loadingFailed: PropTypes.string.isRequired,
    showControls: PropTypes.bool.isRequired,
    checkPresence: PropTypes.bool.isRequired
};

PDFPreview.defaultProps = {
    showControls: true,
    checkPresence: false
}

export default PDFPreview;